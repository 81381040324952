//Stripe Connect onboarding, add payments info
//Put mailing list in analytics and allow adding emails to mailing list.
//TODO: Change checkpaymentsvalid call to happen when first signing up and store a value in firestore. Listen to endpoint to change this value.
import React, { useEffect, useState } from "react"
import SEO from "../components/seo"
import betaLogo from "../images/lokalcommissionslogobeta2.png"
import imageCompression from "browser-image-compression"
import {
  addToMailingList,
  getMailingList,
  importCSVMailingList,
} from "../components/maintenance"
import { useDropzone } from "react-dropzone"
import {
  Heading,
  Center,
  Spinner,
  HStack,
  Box,
  Tabs,
  TabPanel,
  TabPanels,
  Checkbox,
  VStack,
  Textarea,
  InputGroup,
  FormControl,
  Input,
  FormHelperText,
  FormLabel,
  Button,
  ButtonGroup,
  useToast,
  useClipboard,
  InputRightAddon,
  Text,
  Img,
  Radio,
  RadioGroup,
  Wrap,
  Grid,
  Stack,
  Flex,
} from "@chakra-ui/react"
import Menu from "../components/menu"
import { Table, Thead, Th, Tr, Tbody, Td } from "@chakra-ui/table"
import useFirebase from "../components/useFirebase"
import { FaStripe } from "react-icons/fa"
import { Controller, useForm } from "react-hook-form"
import { navigate } from "gatsby"

// Use the subscribed object to determine if the current user is subscribed.
// Subscribed == "14-Day Free Trial", show upgrade bar.
// Subscribed ==   false, disable link, show upgrade bar.
// Use flutter transaction calculation to see if active.

const Carousel = props => {
  const arrowStyles = {
    cursor: "pointer",
    pos: "absolute",
    top: "50%",
    w: "auto",
    mt: "-22px",
    p: "16px",
    color: "white",
    fontWeight: "bold",
    fontSize: "18px",
    transition: "0.6s ease",
    borderRadius: "0 3px 3px 0",
    userSelect: "none",
    _hover: {
      opacity: 0.8,
      bg: "black",
    },
  }

  const slides = props.slides

  const [currentSlide, setCurrentSlide] = useState(0)

  const slidesCount = slides.length

  const prevSlide = () => {
    setCurrentSlide(s => (s === 0 ? slidesCount - 1 : s - 1))
  }
  const nextSlide = () => {
    setCurrentSlide(s => (s === slidesCount - 1 ? 0 : s + 1))
  }

  const carouselStyle = {
    transition: "all .5s",
    ml: `-${currentSlide * 100}%`,
  }

  return (
    <Flex
      w="full"
      bg={"gray.200"}
      p={10}
      alignItems="center"
      justifyContent="center"
    >
      <Flex w="full" overflow="hidden" pos="relative">
        <Flex h="400px" w="full" {...carouselStyle}>
          {slides.map((slide, sid) => (
            <Box key={`slide-${sid}`} boxSize="full" shadow="md" flex="none">
              <Text
                color="white"
                fontSize="xs"
                p="8px 12px"
                pos="absolute"
                top="0"
              >
                {sid + 1} / {slidesCount}
              </Text>
              <Img src={slide.preview} boxSize="full" backgroundSize="cover" />
            </Box>
          ))}
        </Flex>
        <Text {...arrowStyles} left="0" onClick={prevSlide}>
          &#10094;
        </Text>
        <Text {...arrowStyles} right="0" onClick={nextSlide}>
          &#10095;
        </Text>
      </Flex>
    </Flex>
  )
}
const Preview = props => {
  let type = props.type
  let files = props.files
  if (type === "grid") {
    return (
      <Grid bg={"white"} templateColumns={"repeat(2,1fr)"} gap={1}>
        {files.map(file => (
          <Box w={"100%"} h={"auto"} key={file.name} m={0}>
            <Img src={file.preview} size={"sm"} objectFit={"contain"} m={0} />
          </Box>
        ))}
      </Grid>
    )
  } else if (type === "carousel") {
    //This will be the carousel layout example.
    return <Carousel slides={files} />
  }
}
const Dashboard = ({ location }) => {
  const firebase = useFirebase()
  const toast = useToast()
  const [loadSub, setLoadSub] = useState(false)
  const [totalMailingList, setTotalMailingList] = useState([null])
  const [loadSign, setLoadSign] = useState(false)
  const [user, setUser] = useState(null)
  const [name, setName] = useState(null)
  const [value, setValue] = useState("all")
  const [link, setLink] = useState(null)
  const [formData, setForm] = useState({ contact: {} })
  const [account, setAccount] = useState(null)
  const [loaded, setLoad] = useState(false)
  const { onCopy } = useClipboard(location.origin + "/c/" + name)
  const [tabIndex, setTabIndex] = useState(0)
  const [emailSubject, setEmailSubject] = useState("")
  const [acceptingCommissions, setAcceptingCommissions] = useState(false)
  const [files, setFiles] = useState([])
  const [stripeId, setStripeId] = useState(null)
  const [profilePic, setProfilePic] = useState(null)
  const [mailAddress, setMailAddress] = useState("")
  const [firstUpload, setFirstUpload] = useState(false)
  const [disablePublish, setDisabledPublish] = useState(true)
  const [showAnalyticsBanner, setAnalyticsBanner] = useState(false)
  const [layoutValue, setLayoutValue] = useState("grid")
  let bulkMailingListRef = ""
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: async acceptedFiles => {
      setFiles(
        acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      )
      setDisabledPublish(false)
    },
  })
  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach(file => URL.revokeObjectURL(file.preview))
    },
    [files]
  )
  const handleTabChange = index => {
    //0 is Profile form , 1 is Analytics , 2 is Payments , 3 is newsletter send page
    setTabIndex(index)
  }
  const sendOnboardingEmail = values => {
    let requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        name: name,
        email: formData.email,
      }),
    }
    fetch(
      "https://www.lokalcr.com/.netlify/functions/onboard",
      requestOptions
    ).catch(err => console.log(err))
  }
  const storeArtist = async (data, link) => {
    await setLoadSub(true)
    await firebase
      .firestore()
      .collection("artists")
      .doc(link)
      .collection("details")
      .doc("form")
      .set(data, { merge: true })
      .then(() => {
        firstUpload
          ? toast({
              title: "Profile Created",
              description:
                "You have just successfully created your Lokal page. Hit the copy button to copy the link to your page and share it on social media.",
              status: "success",
              duration: 9000,
              isClosable: true,
            })
          : toast({
              title: "Profile Updated",
              description: "Your Profile has now been updated",
              status: "success",
              duration: 9000,
              isClosable: true,
            })
        setForm(oldData => {
          return { ...oldData, data }
        })
        console.log("Stripe ID is:" + formData.stripe_account || " Empty")
        setLoadSub(false)
        navigate("/c/" + formData.name)
      })
      .catch(err => {
        console.log(err)
        toast({
          title: "Failed to update profile",
          description: "Your Profile was not updated",
          status: "error",
          duration: 9000,
          isClosable: true,
        })
      })
    firstUpload
      ? sendOnboardingEmail()
      : console.log("Not the first submission.")
    firstUpload
      ? setAnalyticsBanner(true)
      : console.log("Not the first submission")
  }
  const { handleSubmit, register, reset, control } = useForm({
    defaultValues: {
      menu: [{ service: "Portrait", price: 5.0 }],
      paypal: false,
      kofi: false,
    },
  })
  const {
    handleSubmit: handleSubscriptionForm,
    register: registerSubscriptionForm,
  } = useForm()
  useEffect(() => {
    const logIn = () => {
      console.log("Firebase init, checking auth")
      firebase.auth().onAuthStateChanged(user => {
        user ? setUser(user) : console.log("No user is signed in.")
        firebase
          .firestore()
          .collection("usernames")
          .doc(user.displayName.toLowerCase())
          .get()
          .then(doc => {
            if (doc.exists) {
              let response = doc.data()
              setLink(response.id)
            }
          })
      })
    }
    firebase ? logIn() : console.log("Firebase not init")
  }, [firebase, user])
  useEffect(() => {
    const getData = form => {
      //This only runs if the user has filled in the information.
      //It will immediately switch to the newsletter tab.
      handleTabChange(3)
      setForm(form)
      // eslint-disable-next-line react-hooks/exhaustive-deps
      firebase
        .firestore()
        .collection("artists")
        .doc(link)
        .get()
        .then(doc => {
          doc.exists
            ? setAccount(doc.data())
            : console.log("No doc found at the link")
          // eslint-disable-next-line react-hooks/exhaustive-deps
          setAcceptingCommissions(formData.allowed)
          setLoad(true)

          link
            ? getMailingList(link, firebase).then(async res => {
                setTotalMailingList(res)
                console.log("Mail array is: " + JSON.stringify(res))
              })
            : console.log("Firebase not init.")

          // account ? checkSubscription() : console.log("")
        })
      const imageRef = firebase
        .storage()
        .ref()
        .child(name + "/profile.jpg")
      imageRef
        .getDownloadURL()
        .then(url => {
          setProfilePic(url)
        })
        .catch(error => {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case "storage/object-not-found":
              // File doesn't exist
              setProfilePic(betaLogo)
              break
            case "storage/unauthorized":
              // User doesn't have permission to access the object
              break
            case "storage/canceled":
              // User canceled the upload
              break

            // ...

            case "storage/unknown":
              // Unknown error occurred, inspect the server response
              break
            default:
              setProfilePic(betaLogo)
              break
          }
        })
    }
    link
      ? // eslint-disable-next-line react-hooks/exhaustive-deps
        firebase
          .firestore()
          .collection("artists")
          .doc(link)
          .collection("details")
          .doc("form")
          .get()
          .then(doc => {
            doc.exists
              ? getData(doc.data())
              : firebase
                  .firestore()
                  .collection("artists")
                  .doc(link)
                  .get()
                  .then(doc => {
                    doc.exists
                      ? setForm(doc.data())
                      : console.log("No doc found at the link")
                    setFirstUpload(true)
                    setLoad(true)
                  })
          })
      : console.log("User account not initialized.")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [link, name])
  useEffect(() => {
    console.log("Document data response:", formData)
    setName(formData ? formData.name : "" || account ? account.name : "")
    setTimeout(() => {
      reset({
        menu: formData.menu,
        paypal: formData.paypal,
        kofi: formData.kofi,
      }) /*
      setConfig({
        public_key: "FLWPUBK-cb1e26efb2b0c035ec514ed54dd06a7b-X",
        tx_ref: Date.now(),
        amount: 6,
        payment_plan: "34104",
        currency: "USD",
        payment_options: "card",
        customer: {
          email: formData.email,
          phonenumber: null,
          name: formData.name,
        },
        meta: {
          id: link,
        },
        customizations: {
          title: "Lokal Commissions",
          description: "Early Access Subscription",
          logo: "https://art.lokalcr.com/icons/icon-144x144.png",
        },
      })*/
    }, 1000)
  }, [formData, account, reset])

  //const handleFlutterPayment = useFlutterwave(flutterConfig)
  const onSubmit = values => {
    values.name = name
    values.email = user.email
    storeArtist(values, link)
  }
  const onSubmitSubscription = values => {
    setLoadSub(true)
    //Use check payments valid here instead. And only call if no subscription plan exists.
    let requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        name: name,
        logo: profilePic,
        description: formData.description,
        currency: "USD",
        price: values.price,
        account: formData.stripe_account,
      }),
    }
    fetch("https://www.lokalcr.com/.netlify/functions/product", requestOptions)
      .then(response => response.json())
      .then(res => {
        storeArtist(
          {
            subscription_id: res.subscriptionId,
            subscription_price: values.price,
            subscription_description: values.subscription_description,
          },
          link
        )
        console.log(res)
      })
      .catch(err => console.log(err))
    setLoadSub(false)
  }
  const sendMail = async subscription => {
    const url = "https://api.cloudinary.com/v1_1/lokalcr-com/image/upload"
    const date = Date.now()
    const body = new FormData()
    let responseArray = []
    let mailingList = []
    await setLoadSub(true)
    //Awaits are important to make sure each part of the code is complete before the next part
    //Get email list first
    //This results in nested array, need to fix it eventually.
    if (subscription === "paid") {
      let newArray = await getMailingList(link, firebase, subscription)
      newArray.map(item => {
        console.log(Object.keys(item))
        return mailingList.push(Object.keys(item))
      })
    } else {
      let newArray = totalMailingList
      newArray.map(item => {
        console.log(Object.keys(item))
        return mailingList.push(Object.keys(item))
      })
    }

    for await (let file of files) {
      const options = { maxSizeMB: 0.6 }
      let compressedFile = await imageCompression(file, options)
      body.append("file", compressedFile)
      body.append("tags", [user, date])
      body.append("upload_preset", "basic_upload")
      let requestOptions = {
        method: "POST",
        body: body,
      }
      await fetch(url, requestOptions)
        .then(res => res.json())
        .then(response => {
          console.log(response.secure_url)
          responseArray.push(response.secure_url)
        })
        .catch(err => console.log(err))
    }
    //Send email here
    //Later add upsell to free users when doing posts for all users.

    let requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        name,
        link,
        responseArray,
        location,
        mailingList,
        subject: emailSubject,
        type: layoutValue,
      }),
    }
    await fetch(
      "https://www.lokalcr.com/.netlify/functions/newsletter",
      requestOptions
    )
      .then(response => console.log(response))
      .catch(err => console.log(err))
    //Store urls in firestore
    await firebase
      .firestore()
      .collection("artists")
      .doc(link)
      .collection("details")
      .doc("newsletters")
      .set({ [date]: responseArray }, { merge: true })
      .then(() =>
        toast({
          title: "Newsletter Sending Complete",
          description: "Successfully Sent Newsletter",
          status: "success",
          duration: 9000,
          isClosable: true,
        })
      )
      .catch(err => console.log(err))
    await setLoadSub(false)
    setDisabledPublish(true)
  }
  //Finish this profile pic upload function
  const updateProfilePic = value => {
    console.log("Value is: " + value)
    let image = value[0]
    const imageRef = firebase
      .storage()
      .ref()
      .child(name + "/profile.jpg")
    imageRef.put(image)
    firebase
      .auth()
      .currentUser.updateProfile({ photoUrl: name + "/profile.jpg" })
    setProfilePic(URL.createObjectURL(image))
  }
  /* const checkSubscription = () => {
    let currentDate = Date.now()
    let freediff = currentDate - account.joined

    if (freediff < 1210000000) {
      console.log("Free trial still active")
      setSub("14-Day Trial")
    } else {
      let subdiff = currentDate - formData.flutterTransaction
      if (subdiff < 2592000000) {
        console.log("Free trial elapsed, but subscription is active")
        setSub("Basic subscription")
      } else {
        console.log("Free trial over, and subscription inactive.")
        setSub(false)
      }
    }
  }*/

  const handleOnboard = (partial = false) => {
    //Onboarding to connect function
    if (partial) {
      let requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          id: formData.stripe_account,
          partial: partial,
        }),
      }
      fetch(
        "https://www.lokalcr.com/.netlify/functions/account",
        requestOptions
      )
        .then(response => response.json())
        .then(res => {
          console.log(res)
          window.open(res.url, "_blank", "noopener noreferrer")
          //Confirm finished onboarding
        })
        /*.then(res => setSecret(res))*/
        .catch(err =>
          toast({
            title: "Failed.",
            description: "Failed to create Stripe account.",
            status: "error",
            duration: 9000,
            isClosable: true,
          })
        )
    } else {
      let requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: user.email,
          partial: partial,
        }),
      }
      fetch(
        "https://www.lokalcr.com/.netlify/functions/account",
        requestOptions
      )
        .then(response => response.json())
        .then(res => {
          console.log(res)
          window.open(res.url, "_blank", "noopener noreferrer")
          setStripeId(res.id)
          storeArtist({ stripe_account: stripeId }, link)
          //Confirm finished onboarding
        })
        /*.then(res => setSecret(res))*/
        .catch(err =>
          toast({
            title: "Failed.",
            description: "Failed to create Stripe account.",
            status: "error",
            duration: 9000,
            isClosable: true,
          })
        )
    }
  }
  const signOut = () => {
    setLoadSign(true)
    firebase
      .auth()
      .signOut()
      .then(() => {
        toast({
          title: "Sign Out Successful",
          description: "You will now be redirected to the homepage.",
          status: "success",
          duration: 9000,
          isClosable: true,
        })
        navigate("/")
      })
      .catch(error => {
        toast({
          title: "Sign Out Failed",
          description: "Unable to Sign Out",
          status: "error",
          duration: 9000,
          isClosable: true,
        })
        console.log(error)
        setLoadSign(false)
      })
  }
  return (
    <VStack px={"auto"} bg={"#F2D6D3"} overflow={"hidden"} minH={"100vh"}>
      <HStack
        bg={"white"}
        borderRadius={"md"}
        p={0}
        mt={2}
        justify={"space-between"}
        size={"sm"}
        wrap={"wrap"}
        maxW={"100%"}
        overflow={"auto"}
      >
        <Img
          boxSize={"106px"}
          m={0}
          src={betaLogo}
          size={"sm"}
          onClick={() => navigate("/")}
        />
        <ButtonGroup size={"sm"} color={"black"} m={0}>
          <Button
            bg={"white"}
            fontWeight={"bold"}
            size={"sm"}
            onClick={() => handleTabChange(0)}
          >
            FORM
          </Button>
          <Button
            bg={"white"}
            fontWeight={"bold"}
            size={"sm"}
            onClick={() => handleTabChange(3)}
          >
            PUBLISH
          </Button>
          <Button
            bg={"white"}
            fontWeight={"bold"}
            size={"sm"}
            onClick={() => handleTabChange(2)}
          >
            PAYMENTS
          </Button>
          <Button
            bg={"white"}
            fontWeight={"bold"}
            size={"sm"}
            onClick={() => handleTabChange(1)}
          >
            ANALYTICS
          </Button>
          <a href={"mailto:support@lokalcr.com"}>
            <Button bg={"white"} fontWeight={"bold"} size={"sm"}>
              SUPPORT
            </Button>
          </a>
          <Button
            bg={"white"}
            fontWeight={"bold"}
            size={"sm"}
            onClick={() => signOut()}
            isLoading={loadSign}
          >
            SIGN OUT
          </Button>
        </ButtonGroup>
      </HStack>
      {showAnalyticsBanner ? (
        <HStack
          mt={2}
          bg={"#F6DD8F"}
          borderRadius={"md"}
          wrap={"wrap"}
          justify={"center"}
        >
          <Text>
            Go to the Analytics tab to import addresses to your mailing list.
          </Text>
          <Button
            colorScheme={"#F6DD8F"}
            fontWeight={"bold"}
            size={"sm"}
            variant={"outline"}
            onClick={() => {
              setTabIndex(1)
              /*handleFlutterPayment({
              callback: response => {
                console.log(response)
                closePaymentModal() // this will close the modal programmatically
              },
              onClose: () => {},
            })*/
            }}
          >
            Analytics
          </Button>
        </HStack>
      ) : (
        console.log("Don't show analytics banner.")
      )}
      <SEO title="Dashboard" />
      <Center>
        {!loaded ? (
          <Center>
            <Spinner mt={40} />
          </Center>
        ) : (
          <VStack
            width={{ base: "100%", sm: 550, md: 600, lg: 700 }}
            bg={"white"}
            mx={"auto"}
            borderRadius={"md"}
            mt={5}
          >
            <Center>
              {/*<Img
                src={
                  user.photourl ? user.photourl : betaLogo
                }
              />*/}
              <Wrap justify={"space-evenly"} mt={5} spacing={5}>
                <Heading>{name}</Heading>
                <Img
                  src={profilePic}
                  boxSize={"50px"}
                  borderRadius={"md"}
                  ml={1}
                />
              </Wrap>
            </Center>
            <Tabs index={tabIndex} onChange={handleTabChange}>
              <TabPanels>
                <TabPanel>
                  <Center>
                    <Button bg={"#F6DD8F"} fontWeight={"bold"} as={"label"}>
                      Change Profile Picture
                      <input
                        type="file"
                        onChange={e => updateProfilePic(e.target.files)}
                        accept={".jpeg, .png, .jpg, .tiff, .gif"}
                        style={{ display: "none" }}
                      />
                    </Button>
                  </Center>

                  <FormControl>
                    <FormLabel>Your Link:</FormLabel>
                    <InputGroup>
                      <Input
                        isReadOnly
                        value={location.origin + "/c/" + name}
                      />
                      <InputRightAddon
                        bg={"#F6DD8F"}
                        p={0}
                        children={
                          <Button
                            bg={"#F6DD8F"}
                            fontWeight={"bold"}
                            onClick={onCopy}
                          >
                            COPY
                          </Button>
                        }
                      />
                    </InputGroup>
                  </FormControl>
                  <FormControl>
                    <FormLabel>Email Address:</FormLabel>
                    <Input isReadOnly value={user ? user.email : ""} />
                  </FormControl>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <FormControl isRequired>
                      <FormLabel>About</FormLabel>
                      <FormHelperText id="about-helper-text">
                        Describe yourself or your work
                      </FormHelperText>
                      <Textarea
                        ref={register}
                        name="about"
                        id="about"
                        aria-describedby="about-helper-text"
                        defaultValue={formData.allowed}
                        placeholder={""}
                      />
                    </FormControl>
                    <FormControl isRequired>
                      <FormLabel>Terms and Conditions</FormLabel>
                      <FormHelperText id="terms-helper-text">
                        What are your Terms and Conditions?
                      </FormHelperText>
                      <Textarea
                        ref={register}
                        name={"terms"}
                        placeholder="Set your terms and conditions here."
                        defaultValue={formData.terms}
                        aria-describedby={"terms-helper-text"}
                      />
                    </FormControl>

                    {acceptingCommissions ? (
                      <>
                        <FormControl isRequired>
                          <FormLabel>Will do</FormLabel>
                          <FormHelperText id="allowed-helper-text">
                            What kind of Commissions do you accept?
                          </FormHelperText>
                          <Textarea
                            ref={register}
                            name="allowed"
                            id="allowed"
                            aria-describedby="allowed-helper-text"
                            defaultValue={formData.allowed}
                            placeholder={""}
                          />
                        </FormControl>
                        <FormControl>
                          <FormLabel>Will not do</FormLabel>
                          <FormHelperText id="notAllowed-helper-text">
                            What kind of Commissions do you reject?
                          </FormHelperText>
                          <Textarea
                            ref={register}
                            name="notAllowed"
                            id="notAllowed"
                            aria-describedby="notAllowed-helper-text"
                            defaultValue={formData.notAllowed}
                            placeholder={""}
                          />
                        </FormControl>
                        <Menu
                          name={"menu"}
                          control={control}
                          register={register()}
                          title={formData.menuHeading}
                          first={acceptingCommissions}
                        />
                        <FormControl isRequired>
                          <FormLabel>Available Slots</FormLabel>
                          <FormHelperText id="slots-helper-text">
                            How many commission slots do you have open?
                          </FormHelperText>
                          <Input
                            id={"slots"}
                            defaultValue={formData.slots || 0}
                            aria-describedby={"slots-helper-text"}
                            ref={register}
                            type={"number"}
                            name={"slots"}
                            placeholder={0}
                          />
                        </FormControl>
                        <FormControl isRequired>
                          <FormLabel>Payment Methods</FormLabel>
                          <FormHelperText>
                            What Payment Methods do you accept for Commissions?
                          </FormHelperText>
                          <Controller
                            name={"paypal"}
                            control={control}
                            defaultValue={formData.paypal || ""}
                            render={({ onChange, name, value }) => (
                              <Checkbox
                                value={true}
                                name={name}
                                isChecked={value}
                                spacing={"0.1rem"}
                                onChange={e => onChange(e.target.checked)}
                              >
                                Paypal
                              </Checkbox>
                            )}
                          />
                          <Controller
                            name={"kofi"}
                            control={control}
                            defaultValue={formData.kofi || ""}
                            render={({ onChange, value, name }) => (
                              <Checkbox
                                value={true}
                                name={name}
                                isChecked={value}
                                spacing={"0.1rem"}
                                onChange={e => onChange(e.target.checked)}
                              >
                                Ko-fi
                              </Checkbox>
                            )}
                          />
                        </FormControl>
                      </>
                    ) : (
                      <Button
                        onClick={() => setAcceptingCommissions(true)}
                        bg={"#F6DD8F"}
                        size={"sm"}
                      >
                        ADD A COMMISSION MENU
                      </Button>
                    )}

                    <FormControl>
                      <FormLabel>Instagram</FormLabel>
                      <FormHelperText>
                        Link to your instagram account.
                      </FormHelperText>

                      <Input
                        name={"contact.instagram"}
                        ref={register({
                          pattern: /(?:(?:http|https):\/\/)?(?:www\.)?(?:instagram\.com|instagr\.am)\/([A-Za-z0-9-_.]+)/im,
                        })}
                        placeholder="Instagram"
                        defaultValue={formData.contact.instagram || ""}
                      />
                    </FormControl>
                    <FormControl>
                      <Input
                        name={"contact.deviant"}
                        ref={register({ pattern: /^(?:https?:)?\/\//im })}
                        placeholder="DeviantArt"
                        defaultValue={formData.contact.deviant || ""}
                      />
                    </FormControl>
                    <FormControl>
                      <Input
                        name={"contact.twitter"}
                        ref={register({
                          pattern: /(?:(?:http|https):\/\/)?(?:www\.)?twitter\.com\/@?([A-z0-9_]) /im,
                        })}
                        placeholder="Twitter"
                        defaultValue={formData.contact.twitter || ""}
                      />
                    </FormControl>
                    <FormControl>
                      <Input
                        name={"contact.discord"}
                        ref={register({ pattern: /^(?:https?:)?\/\//im })}
                        placeholder="Discord"
                        defaultValue={formData.contact.discord || ""}
                      />
                    </FormControl>
                    <FormControl>
                      <Input
                        name={"contact.tumblr"}
                        ref={register({ pattern: /^(?:https?:)?\/\//im })}
                        placeholder="Tumblr"
                        defaultValue={formData.contact.tumblr || ""}
                      />
                    </FormControl>
                    <FormControl>
                      <Input
                        name={"contact.kofi"}
                        ref={register({ pattern: /^(?:https?:)?\/\//im })}
                        placeholder="Ko-Fi"
                        defaultValue={formData.contact.kofi || ""}
                      />
                    </FormControl>
                    <FormControl>
                      <Input
                        name={"contact.pixiv"}
                        ref={register({ pattern: /^(?:https?:)?\/\//im })}
                        placeholder="Pixiv"
                        defaultValue={formData.contact.pixiv || ""}
                      />
                    </FormControl>
                    <FormControl>
                      <Input
                        name={"contact.reddit"}
                        ref={register({
                          pattern: /(?:https?:)?\/\/(?:[a-z]+\.)?reddit\.com\/(?:u(?:ser)?)\/([A-z0-9\-_]*)\/?/im,
                        })}
                        placeholder="Reddit"
                        defaultValue={formData.contact.reddit || ""}
                      />
                    </FormControl>
                    <Center>
                      <Button
                        m={5}
                        bg={"#F6DD8F"}
                        type={"submit"}
                        isLoading={loadSub}
                      >
                        SUBMIT
                      </Button>
                    </Center>
                  </form>
                </TabPanel>
                <TabPanel>
                  <VStack w={"100%"} px={10}>
                    {account ? (
                      <>
                        <Center>
                          <Text>
                            Commission Requests:{" "}
                            {account.submissions ? account.submissions : 0}
                          </Text>
                        </Center>
                        <Button
                          bg={"#F6DD8F"}
                          size={"sm"}
                          align={"center"}
                          isLoading={loadSub}
                          onClick={() => {
                            bulkMailingListRef.click()
                            setLoadSub(true)
                          }}
                        >
                          Import CSV Mailing List
                          <input
                            type="file"
                            ref={mailingInput => {
                              bulkMailingListRef = mailingInput
                            }}
                            style={{ display: "none" }}
                            onChange={e => {
                              importCSVMailingList(
                                e.target.files[0],
                                link,
                                firebase
                              )
                                .then(async res => {
                                  console.log(res)
                                  let emailList = res
                                  /* res.map(
                                    item => item.Email1Value
                                  )*/
                                  console.log(emailList)
                                  let newList = totalMailingList
                                    .slice()
                                    .concat(emailList)
                                  console.log(newList)
                                  setTotalMailingList(newList)
                                  toast({
                                    title: "Mailing List Imported",
                                    description:
                                      "You have successfully imported your mailing list.",
                                    status: "success",
                                    duration: 9000,
                                    isClosable: true,
                                  })
                                })
                                .catch(err => {
                                  toast({
                                    title: "Failed to Import Mailing List",
                                    description:
                                      "Your mailing list has failed to import. Please refresh the page and try again, or contact support for assistance.",
                                    status: "error",
                                    duration: 9000,
                                    isClosable: true,
                                  })
                                })
                              setLoadSub(false)
                            }}
                          />
                        </Button>
                        <FormControl>
                          <FormHelperText>
                            Add Email to Mailing List
                          </FormHelperText>
                          <InputGroup>
                            <Input
                              type={"email"}
                              value={mailAddress}
                              onChange={e => setMailAddress(e.target.value)}
                            />
                            <InputRightAddon
                              bg={"#F6DD8F"}
                              p={0}
                              children={
                                <Button
                                  bg={"#F6DD8F"}
                                  size={"sm"}
                                  onClick={() => {
                                    addToMailingList(
                                      mailAddress,
                                      link,
                                      undefined,
                                      firebase
                                    )
                                    let newList = totalMailingList.slice()
                                    newList.push({
                                      [mailAddress]: {
                                        sign_up_date: null,
                                        type: "free",
                                        revenue: 0,
                                      },
                                    })
                                    setTotalMailingList(newList)
                                  }}
                                >
                                  ADD
                                </Button>
                              }
                            />
                          </InputGroup>
                        </FormControl>
                        <Box overflowX={"scroll"} maxW={"80vw"}>
                          <Table size={{ base: "sm", md: "md" }}>
                            <Thead>
                              <Tr>
                                <Th>Email Address</Th>
                                <Th>Subscription Type</Th>
                                <Th>Revenue</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {totalMailingList.map(customer =>
                                customer
                                  ? Object.keys(customer).map(item => (
                                      <Tr key={item}>
                                        <Td>{item}</Td>
                                        <Td>{customer[item].type}</Td>
                                        <Td>{customer[item].revenue}</Td>
                                      </Tr>
                                    ))
                                  : console.log("Mailing list is null")
                              )}
                            </Tbody>
                          </Table>
                        </Box>
                      </>
                    ) : (
                      <Text>
                        When your form is live, you'll be able to get your form
                        submission analytics.
                      </Text>
                    )}
                  </VStack>
                </TabPanel>
                <TabPanel>
                  {/* subscribed ? (
                    <Center>
                      <Text>Current Subscription: {subscribed}</Text>
                    </Center>
                  ) : (
                    <>
                      <Center>
                        <Text>Current Subscription: Inactive</Text>
                      </Center>
                      <Center>
                        <Text>
                          Subscribe to Commissions by Lokal Creatives for only
                          $6.00/month.
                        </Text>
                      </Center>
                      <Center>
                        <Button
                          bg={"#F6DD8F"}
                          fontWeight={"bold"}
                          onClick={() =>
                            handleFlutterPayment({
                              callback: response => {
                                console.log(response)
                                closePaymentModal() // this will close the modal programmatically
                              },
                              onClose: () => {},
                            })
                          }
                        >
                          SUBSCRIBE
                        </Button>
                      </Center>
                    </>
                        )*/}

                  {
                    //If stripe id this means at least partially complete. If there's a subscription id this means there is a finished subscription object for the artist. If there's no subscription id, and there is a stripe id, check if payments are valid. If payments are valid then artist can create subscription.
                    formData.stripe_account ? (
                      <Box>
                        <Text>
                          Here is your payments dashboard link:
                          <Button
                            variant={"link"}
                            onClick={() =>
                              window.open(
                                "https://dashboard.stripe.com",
                                "_blank",
                                "noopener noreferrer"
                              )
                            }
                          >
                            Stripe Dashboard
                          </Button>
                        </Text>
                        <form
                          onSubmit={handleSubscriptionForm(
                            onSubmitSubscription
                          )}
                        >
                          <FormControl>
                            <FormHelperText>
                              Choose a price for your photo dump subscription.
                            </FormHelperText>
                            <Input
                              type={"number"}
                              defaultValue={formData.subscription_price}
                              name={"price"}
                              placeholder={5}
                              ref={registerSubscriptionForm}
                            />
                          </FormControl>
                          <FormControl>
                            <FormHelperText>
                              Describe what will be in your paid subscription.
                            </FormHelperText>
                            <Textarea
                              ref={registerSubscriptionForm}
                              name="subscription_description"
                              id="subscription_description"
                              aria-describedby="subscription_description-helper-text"
                              defaultValue={formData.subscription_description}
                              placeholder={""}
                            ></Textarea>
                          </FormControl>
                          <Button
                            m={5}
                            bg={"#F6DD8F"}
                            type={"submit"}
                            isLoading={loadSub}
                          >
                            SUBMIT
                          </Button>
                        </form>
                      </Box>
                    ) : (
                      <>
                        <Text>
                          Here you'll be able to access your payment dashboard.
                        </Text>
                        <Button
                          onClick={() => handleOnboard()}
                          bg={"#635bff"}
                          color={"white"}
                          size={"sm"}
                          justifyItems={"space-between"}
                        >
                          Connect with <FaStripe w={100} h={100} />
                        </Button>
                      </>
                    )
                  }
                </TabPanel>
                <TabPanel>
                  {
                    // Update commission menu button, send mails, basic editor
                  }
                  {formData.stripe_account ? (
                    formData.subscription_id ? (
                      console.log("Has created subscription.")
                    ) : (
                      <Text>
                        Go to the{" "}
                        <Button
                          variant={"link"}
                          color={"black"}
                          onClick={() => handleTabChange(2)}
                        >
                          Payments
                        </Button>{" "}
                        page to finish creating your paid subscription.
                      </Text>
                    )
                  ) : (
                    <Text>
                      Go to the{" "}
                      <Button
                        variant={"link"}
                        color={"black"}
                        onClick={() => handleTabChange(2)}
                      >
                        Payments
                      </Button>{" "}
                      page to create a paid subscription.
                    </Text>
                  )}

                  <Box>
                    <Box
                      {...getRootProps()}
                      p={3}
                      borderWidth={"1px"}
                      bg={"#F6DD8F"}
                    >
                      <Center>
                        Drag and drop your images here, or click to select your
                        images.
                      </Center>

                      <input {...getInputProps()} />
                    </Box>
                    <Stack spacing={5}>
                      <Wrap>
                        <Text>Choose your audience: </Text>
                        <RadioGroup value={value} onChange={setValue} my={3}>
                          <Radio value={"all"}>All Subscribers</Radio>
                          <Radio value={"paid"}>Paid Subscribers</Radio>
                        </RadioGroup>
                      </Wrap>

                      <FormControl>
                        <Input
                          value={emailSubject}
                          onChange={e => setEmailSubject(e.target.value)}
                          placeholder={"Add a subject to your emails."}
                        />
                      </FormControl>
                      <Wrap>
                        <Text>Choose your layout: </Text>
                        <RadioGroup
                          value={layoutValue}
                          onChange={setLayoutValue}
                          my={3}
                        >
                          <Radio value={"grid"}>Grid</Radio>
                          <Radio value={"carousel"}>Carousel</Radio>
                        </RadioGroup>
                      </Wrap>

                      <Preview type={layoutValue} files={files} />
                      <Button
                        bg={"#F6DD8F"}
                        onClick={() => sendMail(value)}
                        isLoading={loadSub}
                        loadingText={"Sending Emails"}
                        disabled={disablePublish}
                        //Add client side progress checker.
                      >
                        PUBLISH
                      </Button>
                    </Stack>
                  </Box>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </VStack>
        )}
      </Center>
    </VStack>
  )
}

export default Dashboard
